@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.spinner {
  --size: 3rem;
  position: fixed;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  display: inline-block;
  border: 2px solid var(--color-primary-1);
  border-top: 2px solid var(--color-neutral-5);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  animation: spin 1.1s linear infinite;
}
